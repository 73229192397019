import { CalendarIcon } from "@heroicons/react/24/solid"
import Headline from "components/general/headline"
import Layout from "components/layout"
import { graphql } from "gatsby"
import moment from "moment"
import * as React from "react"
import { ReactNode } from "react"
import { Helmet } from "react-helmet"

const MTVArticleTemplate = ({ data }) => {
  const article = data.faustball.mtvArticles[0]

  const getDate = (article): ReactNode => {
    return (
      <div className="flex items-center">
        <CalendarIcon
          className="mr-1.5  h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
        <span>{moment(article.date).format("DD.MM.YYYY HH:mm")}</span>
      </div>
    )
  }

  // const seo = {
  //   metaTitle: article.seo?.metaTitle ?? article.title,
  //   metaDescription: article.seo?.metaDescription ?? "",
  // }
  const seo = {
    metaTitle: "",
    metaDescription: "",
  }

  return (
    <Layout seo={seo}>
      <Helmet>
        <link rel="canonical" href={article.link} />
      </Helmet>
      <div className="main-box">
        <Headline
          headline={article.title}
          subHeadline={getDate(article)}
          mtvImported={article.link}
        />

        <div
          className="article mt-2 text-justify leading-6"
          dangerouslySetInnerHTML={{ __html: article.content }}
        />
      </div>
    </Layout>
  )
}

export default MTVArticleTemplate

export const query = graphql`
  query MTVArticleTemplate($id: Int!) {
    faustball {
      mtvArticles(id: $id) {
        id
        title
        date
        link
        slug
        excerpt
        content
      }
    }
    # mtvArticle(id: { eq: $id }) {
    #   date
    #   link
    #   slug
    #   title {
    #     rendered
    #   }
    #   content {
    #     rendered
    #   }
    # }
  }
`
